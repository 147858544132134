import firebase from "firebase";

const firebaseConfig = {
apiKey: "AIzaSyCHoKV0CQU2zctfEt3-8H-cX2skMbMpmsM",
authDomain: "alysei-add21.firebaseapp.com",
databaseURL: "https://alysei-add21-default-rtdb.firebaseio.com",
projectId: "alysei-add21",
storageBucket: "alysei-add21.appspot.com",
messagingSenderId: "843003765744",
appId: "1:843003765744:web:febfa73a5759430ab63335",
measurementId: "G-95HCV7CCZX",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
