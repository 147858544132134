import React, { useState } from 'react';
import italianContentImg1 from '../../assets/landing-page/services/italian-contentimg-1.png';
import italianContentImg2 from '../../assets/landing-page/services/italian-contentimg-2.png';
import italianContentImg3 from '../../assets/landing-page/services/italian-contentimg-3.png';
import importerContentImg from '../../assets/landing-page/services/importer-contentimg.png';
import restaurantContentImg from '../../assets/landing-page/services/restaurent-contentimg.png';
import expertContentImg from '../../assets/landing-page/services/expert-contentimg.png';
import travelContentImg from '../../assets/landing-page/services/travel-contentimg.png';
import buyerContentImg from '../../assets/landing-page/services/buyer-contentimg.png';
import voyagerContentImg from '../../assets/landing-page/services/voyager-contentimg.png';
import profileIcon from '../../assets/landing-page/profile-icon.svg';
import promoteIcon from '../../assets/landing-page/promote-icon.svg';
import marketPlaceIcon from '../../assets/landing-page/market-place-icon.svg';
import shippingIcon from '../../assets/landing-page/shipping-icon.svg';
import restaurantIcon from '../../assets/landing-page/restaurent-icon.svg';
import buyerIcon from '../../assets/landing-page/buyer-icon.svg';
import glassIcon from '../../assets/landing-page/glass-icon.svg';
import findconnectIcon from '../../assets/landing-page/findconnect-icon.svg';
import campaignIcon from '../../assets/landing-page/campaign-icon.svg';
import cuisineIcon from '../../assets/landing-page/cuisine-icon.svg';
import clientIcon from '../../assets/landing-page/client-icon.svg';
import socialIcon from '../../assets/landing-page/social-icon.svg';
import audienceIcon from '../../assets/landing-page/audience-icon.svg';
import foodlistIcon from '../../assets/landing-page/food-list-icon.svg';
import recordIcon from '../../assets/landing-page/record-icon.svg';
import engageIcon from '../../assets/landing-page/engage-icon.svg';
import shortlistIcon from '../../assets/landing-page/shortlist-icon.svg';
import commissionIcon from '../../assets/landing-page/commission-icon.svg';
import productListIcon from '../../assets/landing-page/product-list-icon.svg';
import connectIcon from '../../assets/landing-page/connect-icon.svg';
import searchIcon from '../../assets/landing-page/search-icon.svg';
import searchrecipeIcon from '../../assets/landing-page/search-recipe-icon.svg';
import interactIcon from '../../assets/landing-page/interact-icon.svg';
import membershipIcon from '../../assets/landing-page/membership-icon.svg';
import producerThumbnail from '../../assets/landing-page/producer-thumbnail.png';
import producerVideo from '../../assets/landing-page/Producers-video.mp4';
import italianThumbnail from '../../assets/landing-page/italian-thumbnail.png';
import importerVideo from '../../assets/landing-page/Importers-Distributors-video.mp4';
import language from "../../helpers/language";
const lang = language.getLang();

export const serviceContent = [
    {
        slug: 'italian-food-beverage-producers',
        title: lang.Italian_FB_Producers_Title,
        description: lang.Italian_FB_Producers_Description,
        contentImg: [
            {
                heading: lang.Italian_FB_Producers_Investment_Heading,
                subheading: lang.Italian_FB_Producers_Investment_Subheading,
                desc: lang.Italian_FB_Producers_Investment_Desc,
                badgeInfo: lang.Italian_FB_Producers_Investment_BadgeInfo,
                img: italianContentImg1,
                direction: 'flexRow'
            },
            {
                heading: lang.Italian_FB_Producers_Growth_Heading,
                subheading: lang.Italian_FB_Producers_Growth_Subheading,
                desc: lang.Italian_FB_Producers_Growth_Desc,
                badgeInfo: lang.Italian_FB_Producers_Growth_BadgeInfo,
                img: italianContentImg2,
                direction: 'flexRowReverse'
            },
            {
                heading: lang.Italian_FB_Producers_Future_Heading,
                subheading: lang.Italian_FB_Producers_Future_Subheading,
                desc: lang.Italian_FB_Producers_Future_Desc,
                badgeInfo: lang.Italian_FB_Producers_Future_BadgeInfo,
                img: italianContentImg3,
                direction: 'flexRow'
            }
        ],
        ourServices: {
            title : lang. Italian_FB_Producers_Services_Title,
            desc: lang.Italian_FB_Producers_Services_Desc,
            cardContent: [
              {
                content: [
                  lang.Italian_FB_Producers_Services_Content1,
                  lang.Italian_FB_Producers_Services_Content2,
                  lang.Italian_FB_Producers_Services_Content3,
                  lang.Italian_FB_Producers_Services_Content4,
                  lang.Italian_FB_Producers_Services_Content5,
                  lang.Italian_FB_Producers_Services_Content6
                ],
                icon: [
                  profileIcon,
                  promoteIcon,
                  marketPlaceIcon,
                  shippingIcon,
                  restaurantIcon,
                  buyerIcon
                ]
              }
            ]
          },
          videoUrl: producerVideo,
        thumbnail: producerThumbnail,
        videoTitle: lang.Italian_FB_videoTitle,
        videoDesc: lang.Italian_FB_videoDesc,
        videoSub : lang.Italian_FB_videoSub,
        joinLink : "/register/3"
    },
    {
        slug: 'importers-distributors',
        title: lang.Importers_Distributors_Title,
        description: lang.Importers_Distributors_Description,
        contentImg: [
          {
            heading: lang.Importers_Distributors_ContentImg_Heading,
            subheading: lang.Importers_Distributors_ContentImg_Subheading,
            desc: lang.Importers_Distributors_ContentImg_Desc,
            badgeInfo: lang.Importers_Distributors_ContentImg_BadgeInfo,
            img: importerContentImg,
            direction: "flexRow"
          }
        ],
        ourServices: {
            title: lang.Italian_FB_Producers_Services_Title,
          desc: lang.Importers_Distributors_Services_Desc,
          cardContent: [
            {
              content: [
                lang.Importers_Distributors_Services_Content1,
                lang.Importers_Distributors_Services_Content2,
                lang.Importers_Distributors_Services_Content3,
                lang.Importers_Distributors_Services_Content4,
                lang.Importers_Distributors_Services_Content5,
                lang.Importers_Distributors_Services_Content6
              ],
              icon: [
                profileIcon,
                promoteIcon,
                marketPlaceIcon,
                glassIcon,
                findconnectIcon,
                campaignIcon
              ]
            }
          ]
        },
        videoUrl: importerVideo,
        thumbnail: italianThumbnail,
        videoTitle: lang.Italian_FB_videoTitle,
        videoDesc: lang.Importers_Distributors_videoDesc,
        videoSub : lang.Importers_Distributors_videoSub,
        joinLink: "/register/6"
      },
    {
        slug: 'italian-restaurants',
        title: lang.Italian_Restaurants_Title,
        description: lang.Italian_Restaurants_Description,
        contentImg: [
          {
            heading: lang.Italian_Restaurants_ContentImg_Heading,
            subheading: lang.Italian_Restaurants_ContentImg_Subheading,
            desc: lang.Italian_Restaurants_ContentImg_Desc,
            badgeInfo: lang.Italian_Restaurants_ContentImg_BadgeInfo,
            img: restaurantContentImg,
            direction: "flexRow"
          }
        ],
          ourServices: {
            title: lang.Italian_FB_Producers_Services_Title,
          desc: lang.Italian_Restaurants_Services_Desc,
          cardContent: [
            {
              content: [
                lang.Italian_Restaurants_Services_Content1,
                lang.Italian_Restaurants_Services_Content2,
                lang.Italian_Restaurants_Services_Content3,
                lang.Italian_Restaurants_Services_Content4,
                lang.Italian_Restaurants_Services_Content5,
                lang.Italian_Restaurants_Services_Content6
              ],
              icon: [
                profileIcon,
                cuisineIcon,
                marketPlaceIcon,
                glassIcon,
                shippingIcon,
                clientIcon
              ]
            }
          ]
        },
        joinLink: "/register/9"
      },
    {
        slug: 'voice-of-experts',
        title: lang.Voice_of_Experts_Title,
        description: lang.Voice_of_Experts_Description,
        contentImg: [
          {
            heading: lang.Voice_of_Experts_ContentImg_Heading,
            subheading: lang.Voice_of_Experts_ContentImg_Subheading,
            desc: lang.Voice_of_Experts_ContentImg_Desc,
            badgeInfo: lang.Voice_of_Experts_ContentImg_BadgeInfo,
            img: expertContentImg,
            direction: "flexRow"
          }
        ],
          ourServices: {
            title: lang.Italian_FB_Producers_Services_Title,
          desc: lang.Voice_of_Experts_Services_Desc,
          cardContent: [
            {
              content: [
                lang.Voice_of_Experts_Services_Content1,
                lang.Voice_of_Experts_Services_Content2,
                lang.Voice_of_Experts_Services_Content3,
                lang.Voice_of_Experts_Services_Content4,
                lang.Voice_of_Experts_Services_Content5,
                lang.Voice_of_Experts_Services_Content6
              ],
              icon: [
                profileIcon,
                socialIcon,
                marketPlaceIcon,
                shippingIcon,
                restaurantIcon,
                audienceIcon
              ]
            }
          ]
        },
        joinLink: "/register/7"
      },
    {
        slug: 'travel-agencies',
        title: lang.Traveler_Agencies_Title,
        description: lang.Traveler_Agencies_Description,
        contentImg: [
          {
            heading: lang.Traveler_Agencies_ContentImg_Heading,
            subheading: lang.Traveler_Agencies_ContentImg_Subheading,
            desc: lang.Traveler_Agencies_ContentImg_Desc,
            badgeInfo: lang.Traveler_Agencies_ContentImg_BadgeInfo,
            img: travelContentImg,
            direction: "flexRow"
          }
        ],
        ourServices: {
          title : lang. Italian_FB_Producers_Services_Title,
          desc: lang.Traveler_Agencies_Services_Desc,
          cardContent: [
            {
              content: [
                lang.Traveler_Agencies_Services_Content1,
                lang.Traveler_Agencies_Services_Content2,
                lang.Traveler_Agencies_Services_Content3,
                lang.Traveler_Agencies_Services_Content4,
                lang.Traveler_Agencies_Services_Content5,
                lang.Traveler_Agencies_Services_Content6
              ],
              icon: [
                profileIcon,
                socialIcon,
                marketPlaceIcon,
                glassIcon,
                shippingIcon,
                foodlistIcon
              ]
            }
          ]
        },
        joinLink: "/register/8"
      },
    
    {
        slug: 'voyagers',
        title: lang.Voyagers_Title,
        description: lang.Voyagers_Description,
        contentImg: [
          {
            heading: lang.Voyagers_ContentImg_Heading,
            subheading: lang.Voyagers_ContentImg_Subheading,
            desc: lang.Voyagers_ContentImg_Desc,
            badgeInfo: lang.Voyagers_ContentImg_BadgeInfo,
            img: voyagerContentImg,
            direction: "flexRow"
          }
        ],
          ourServices: {
            title : lang. Italian_FB_Producers_Services_Title,
          desc: lang.Voyagers_Services_Desc,
          cardContent: [
            {
              content: [
                lang.Voyagers_Services_Content1,
                lang.Voyagers_Services_Content2,
                lang.Voyagers_Services_Content3,
                lang.Voyagers_Services_Content4,
                lang.Voyagers_Services_Content5,
                lang.Voyagers_Services_Content6
              ],
              icon: [
                profileIcon,
                searchIcon,
                searchrecipeIcon,
                interactIcon,
                marketPlaceIcon,
                membershipIcon
              ]
            }
          ]
        },
        joinLink: "/register/10"
      },
    
    {
        slug: 'buyers',
        title: lang.Buyers_Title,
        description: lang.Buyers_Description,
        contentImg: [
          {
            heading: lang.Buyers_ContentImg_Heading,
            subheading: lang.Buyers_ContentImg_Subheading,
            desc: lang.Buyers_ContentImg_Desc,
            badgeInfo: lang.Buyers_ContentImg_BadgeInfo,
            img: buyerContentImg,
            direction: "flexRow"
          }
        ],
          ourServices: {
            title : lang. Italian_FB_Producers_Services_Title,
          desc: lang.Buyers_Services_Desc,
          cardContent: [
            {
              content: [
                lang.Buyers_Services_Content1,
                lang.Buyers_Services_Content2,
                lang.Buyers_Services_Content3,
                lang.Buyers_Services_Content4,
                lang.Buyers_Services_Content5,
                lang.Buyers_Services_Content6
              ],
              icon: [
                recordIcon,
                engageIcon,
                shortlistIcon,
                commissionIcon,
                productListIcon,
                connectIcon
              ]
            }
          ]
        },
        joinLink: "/register/11"
      },
    
     
];
