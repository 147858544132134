import React, { Fragment, useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { EditContact } from "./editContact";
import constant from "../../../helpers/constant"
import language from "../../../helpers/language";

export const Contact = (props) => {
  const [contactModel, setcontactModel] = useState(false);
  const [lang] = useState(language.getLang());
  const { profileData: { data }, formValue, } = useSelector((state) => state.memberProfileData);
  console.log(data,"store data in member profile data");
  console.log(formValue,"form value in member profile data");
  

  
  return (
    <Fragment>
      {/* Tab Content left Section */}
      <div className="tab-content-section">
        <div className="">
          <div className="profile-options-lists contactUs">
            {/* <div className="card-title">
              <h5>Contact us</h5>
            </div> */}
            <div className="edit__profile contact" id="edit__profile">
              {data
                ? Object.keys(data.contact_tab).map(
                  (key, index) => {
                    if (key === "email" || key === "phone" || key === "address" || key === "website" || key === "fb_link" || key === "inst_link") {
                      formValue[key] = data.contact_tab[key];
                    }
                  })
                : ""}
              <div className="contact-detail_list">
                <div className="options-list-item">
                  <h4 className="font-size-18 fontNormal pb-2">
                    <i className="fa fa-envelope mr-2" />
                    {lang?.Email}
                  </h4>
                  <div className="list-item-name">
                    <p className="font-14">
                      {(data?.contact_tab?.email)?.toLowerCase()}
                    </p>
                  </div>
                </div>
                {data.contact_tab?.phone != null && data?.contact_tab?.phone != '' && (
                <div className="options-list-item ">
                  <p className="font-size-18 fontNormal pb-2">
                    <i className="fas fa-phone-alt mr-2" />
                    {lang?.Phone_number}
                  </p>
                  <div className="list-item-name">
                    {
                      data?.contact_tab?.phone != null && data?.contact_tab?.phone != '' &&
                      <p className="font-14">
                        {data?.contact_tab?.country_code != "NaN" ? `+${parseInt(data.contact_tab?.country_code)}` : constant.rolesId.producer === data.contact_tab.role_id ? "+39" : "+1"}&nbsp;
                          {data.contact_tab?.phone}
                      </p>
                    }
                  </div>
                </div>
                )}

                {data.contact_tab?.address != null && data.contact_tab?.address != '' && (
                <div className="options-list-item ">
                  <p className="font-size-18 fontNormal pb-2">
                    <i className="fas fa-location-arrow mr-2" />
                    {lang?.only_address}
                  </p>
                  <div className="list-item-name">
                    <p className="font-14">
                    {data.contact_tab?.address1 !== null && data.contact_tab?.address1 != '' ? `${data.contact_tab?.address1}, ${data.contact_tab?.address}` : data.contact_tab?.address}
                    </p>
                  </div>
                </div>
                )}
                {/* <div className="options-list-item">
                  <p className="list-title font-16">
                    <i className="fas fa-location-arrow mr-2" />
                    <strong>Address</strong>
                  </p>
                  <div className="list-item-name">
                    <span>
                      {addressLine2} {addressLine1} {address} 
                          </span>
                  </div>
                </div> */}
                {(data?.contact_tab?.website != 'null' && data?.contact_tab?.website != null && data?.contact_tab?.website != '') ?
                <div className="options-list-item">
                  <p className="font-size-18 fontNormal pb-2">
                    <i className="fas fa-home mr-2" />
                    {lang?.Website}
                  </p>
                  <div className="list-item-name">
                    {data?.contact_tab?.website?.includes("http") ? <a className="font-14" href={`${(data?.contact_tab?.website)}`} target="_blank">
                      {(data?.contact_tab?.website)}
                    </a> : <a className="font-14" href={`https://${(data?.contact_tab?.website)}`} target="_blank">
                      {(data?.contact_tab?.website)}
                    </a>}
                  </div>
                </div>
                : ''}
                {data?.contact_tab?.fb_link !== "null" && data?.contact_tab?.fb_link !== null && data?.contact_tab?.fb_link !== '' &&
                  <div className="options-list-item">
                    <p className="font-size-18 fontNormal pb-2">
                      <i className="fab fa-facebook mr-2" />
                      {lang?.Facebook}
                    </p>
                    <div className="list-item-name">
                      {data?.contact_tab?.fb_link?.includes("http") ? <a className="font-14" href={`${(data?.contact_tab?.fb_link)}`} target="_blank">
                        {(data?.contact_tab?.fb_link)}
                      </a>
                        : <a className="font-14" href={`https://${(data?.contact_tab?.fb_link)}`} target="_blank">
                          {(data?.contact_tab?.fb_link)}
                        </a>}
                    </div>
                  </div>
                }

                {data?.contact_tab?.inst_link && data?.contact_tab?.inst_link !== "null" && data?.contact_tab?.inst_link !== null  && data?.contact_tab?.inst_link !== '' &&
                  <div className="options-list-item">
                    <p className="font-size-18 fontNormal pb-2">
                      <i className="fab fa-instagram mr-2" />
                      Instagram
                    </p>
                    <div className="list-item-name">
                      {data?.contact_tab?.inst_link?.includes("http") ? <a className="font-14" href={`${(data?.contact_tab?.inst_link)}`} target="_blank">
                        {(data?.contact_tab?.inst_link)}
                      </a>
                        : <a className="font-14" href={`https://${(data?.contact_tab?.inst_link)}`} target="_blank">
                          {(data?.contact_tab?.inst_link)}
                        </a>}
                    </div>
                  </div>
                }
              </div>

              <div>
                {contactModel && <EditContact />}
              </div>
            </div>
            <div className="edit_blue_btn contact">
              <button type="button"
                className="btn edit__profile-btn"
                onClick={() => setcontactModel(!contactModel)}
              >
                {lang?.Edit}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
