import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PaginatedComponentView from "../../../components/PaginatedComponent/PaginatedComponentView";
import { getMyLeads, deleteProducerRequest } from "../modules/marketplace";
import moment from "moment";
import firebase from "../../../config//firebase";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import NoData from "../../Buyer/components/CreateRequestView/NoRequestFound";
import Modal from "react-modal";
import { checkSubscription } from "../../home/modules/HomeModule";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation,useHistory } from 'react-router-dom';
import CustomUseEffect from "../../../components/constants";
import language from "../../../helpers/language";
export default function NewLead() {

  const dispatch = useDispatch();

  const [lang] = useState(language.getLang());
  const [leadListing, setLeadListing] = useState("");
  const [page, setPage] = useState(1);
  const [productTypeId, setProductTypeId] = useState("");
  const [buyerName, setBuyerName] = useState("");
  const [leadUnreadMessage, setLeadUnreadMessage] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteRequestId, setDeleteRequestId] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { get_Subscription_Status_Data } = useSelector(
    (state) => state.activityFeedStatus
  );
  let subscriptionData =
    get_Subscription_Status_Data?.subscription?.subscription_plan;
  const [date, setDate] = useState();
  const [daysRemaining, setDaysRemaining] = useState(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const history=useHistory()
  // const id = searchParams.get('id');
  // const tab = searchParams.get("tab");

  
console.log(subscriptionData?.name,"subscriptionData?.name")


  useEffect(() => {
    // Target date: April 10, 2024, 10:23:53
    const targetDate = new Date(date);
    targetDate.setDate(targetDate.getDate() + 15);
    
    // Current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceMs = targetDate - currentDate;

    // Convert milliseconds to days
    const days = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

    // Update state with the number of days remaining
    setDaysRemaining(days);
  }, [date]);

  useEffect(() => {
    dispatch(checkSubscription());
  }, []);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const currentUser = JSON.parse(localStorage.getItem("USER_TYPE")) ?? null;

  CustomUseEffect(async () => {
    await setIsLoader(true);
    await myLeads(page, buyerName, productTypeId);
  }, [page]);

  const myLeads = async (page, buyer_name, product_type) => {
    let leadResponse = await getMyLeads(page, buyer_name, product_type);
    // let data=leadResponse?.requests?.data.find((request)=>request.uuid==id)
    // if(data?.views==1){
    //   history.push(`/marketplace/view-lead/${id}`)
    //   localStorage.setItem("tab", "newlead");
    // }
    await setLeadListing(leadResponse);
    // localStorage.setItem("tab", "newlead");
    await setIsLoader(false);
  };

  const handleRecentOrderPageClick = (e) => {
    setPage(e.selected + 1);
  };

  const getRequestByProductType = async (e) => {
    let productType = e.target.value;
    await setIsLoader(true);
    await setProductTypeId(productType);
    await myLeads(page, buyerName, productType);
  };

  const searchRequests = async () => {
    await setIsLoader(true);
    await myLeads(page, buyerName, productTypeId);
  };

  const resetSearch = async () => {
    await setIsLoader(true);
    await setBuyerName("");
    await myLeads(page, "", productTypeId);
  };

  // Open Delete Request Modal
  const deleteMyRequest = async (uuid) => {
    await setDeleteRequestId(uuid);
    await setDeleteModal(true);
  };

  const producerDeleteRequest = async () => {
    await setDeleteLoader(true);
    let apiResponse = await deleteProducerRequest(deleteRequestId);
    if (apiResponse?.success) {
      await setDeleteMessage(apiResponse?.message);

      await myLeads(page, buyerName, productTypeId);
    }
    await setDeleteLoader(false);
    await setDeleteModal(false);
    setTimeout(() => {
      setDeleteMessage("");
    }, 2500);
  };


  return (
    <>
      <div id="lead" className='servics_blocks middle-long__section-block middleBlockBg disputes'>
        {/* <div className="product-title">
                        <h4>Disputes</h4>
                    </div> */}
        <div className='successmessage'>
          <h4 className='font-bold text-green text-center'>{deleteMessage}</h4>
        </div>
        <div className='productFilter mb--30'>
          <div className='filterDataLeft flexBox gap-4'>
            <div className='searchBar'>
              <input
                type='text'
                className='search form--group'
                name='customer'
                placeholder={lang?.RequestPlaceholder}
                value={buyerName}
                onChange={(e) => setBuyerName(e.target.value)}
              />
              {/* <span className="searchIcon">
                                <i className="fa fa-search" aria-hidden="true"></i>
                            </span> */}
            </div>
            <div className='btn btn--gray'>
              <button type='button' className='btn' onClick={searchRequests}>
                {lang?.Search}
              </button>
            </div>
            <div className='btn btn--gray'>
              <button type='button' className='btn' onClick={resetSearch}>
                Reset
              </button>
            </div>
          </div>
          <div className='filterDataRight'>
            <div className='flexBox form__item items-center gap-2 mb-0'>
              <label className='mb-0'>{lang?.Filter}</label>
              <div className='searchBar'>
                <select
                  className='search form--group'
                  name='product_type'
                  value={productTypeId}
                  onChange={(e) => getRequestByProductType(e)}
                >
                  <option value=''>{lang?.SelectProductType}</option>
                  {leadListing?.success &&
                    leadListing?.product_types.length > 0 &&
                    leadListing?.product_types.map(
                      ({ user_field_option_id, option }) => {
                        return (
                          <option value={user_field_option_id}>{option}</option>
                        );
                      }
                    )}
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* <h1>My Offers</h1> */}
        <div className='product-list'>
          <div className='recentOrder secBlockGap'>
            <div className='itemTable itemTableScroll customer__table'>
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{lang?.BuyerName}</th>
                    <th>{lang?.only_title}</th>
                    <th>{lang?.ProductType}</th>
                    <th>{lang?.Timeleads}</th>
                    <th>{lang?.Action}</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoader ? (
                    <tr>
                      <td colSpan='7'>
                        <FormSpinner />
                      </td>
                    </tr>
                  ) : (
                    leadListing?.success &&
                    leadListing?.requests?.data?.length > 0 &&
                    leadListing?.requests?.data?.map(
                      ({
                        id,
                        first_name,
                        last_name,
                        title,
                        uuid,
                        product_type_name,
                        created_at,
                        show,views
                      }) => {
                        return (
                          <tr >
                            <td style={{ fontWeight: views==0 ? 'bold': 'normal'}}>{id}</td>
                            <td style={{ fontWeight: views==0 ? 'bold': 'normal'}}>{`${first_name} ${last_name}`}</td>
                            <td style={{ fontWeight: views==0 ? 'bold': 'normal'}}>{title}</td>
                            <td style={{ fontWeight: views==0 ? 'bold': 'normal'}}>
                              {product_type_name ? product_type_name : "--"}
                            </td>

                            <td style={{ fontWeight: views==0 ? 'bold': 'normal'}}>
                              {moment(created_at).format("DD MMM YYYY hh:mm A")}
                            </td>
                            <td>
                              <div className='iconGroup'>
                                {show == 1 ? (
                                  <span className=''>
                                    <Link
                                      to={`/marketplace/view-lead/${uuid}`}
                                      title={lang?.View}
                                    >
                                      <svg
                                        width='40'
                                        height='40'
                                        viewBox='0 0 40 40'
                                        fill='none'
                                      >
                                        <rect
                                          width='40.0001'
                                          height='40'
                                          rx='6.48599'
                                          fill='#004577'
                                        />
                                        <path
                                          d='M19.9937 13C14.8056 13 10.2343 16.732 9.0216 21.6224C8.97625 21.8057 9.00293 22.0002 9.09577 22.1631C9.1886 22.326 9.33999 22.444 9.51663 22.491C9.69327 22.5381 9.88068 22.5104 10.0376 22.4141C10.1946 22.3177 10.3083 22.1606 10.3536 21.9773C11.3986 17.7634 15.4469 14.427 19.9937 14.427C24.5405 14.427 28.6017 17.7645 29.6464 21.9773C29.6917 22.1606 29.8054 22.3177 29.9624 22.4141C30.1193 22.5104 30.3067 22.5381 30.4834 22.491C30.66 22.444 30.8114 22.326 30.9042 22.1631C30.9971 22.0002 31.0237 21.8057 30.9784 21.6224C29.7654 16.7309 25.1818 13 19.9937 13ZM20.0009 16.8053C17.5626 16.8053 15.5716 18.8716 15.5716 21.4022C15.5716 23.9328 17.5626 26 20.0009 26C22.4392 26 24.4311 23.9328 24.4311 21.4022C24.4311 18.8716 22.4392 16.8053 20.0009 16.8053ZM20.0009 18.2323C21.6961 18.2323 23.0561 19.6428 23.0561 21.4022C23.0561 23.1616 21.6961 24.573 20.0009 24.573C18.3057 24.573 16.9466 23.1616 16.9466 21.4022C16.9466 19.6428 18.3057 18.2323 20.0009 18.2323Z'
                                          fill='white'
                                        />
                                      </svg>
                                    </Link>
                                  </span>
                                ) : (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handleOpenModal();
                                      setDate(created_at);
                                    }}
                                    className=''
                                  >
                                    <svg
                                      width='40'
                                      height='40'
                                      viewBox='0 0 40 40'
                                      fill='none'
                                    >
                                      <rect
                                        width='40.0001'
                                        height='40'
                                        rx='6.48599'
                                        fill='#004577'
                                      />
                                      <path
                                        d='M19.9937 13C14.8056 13 10.2343 16.732 9.0216 21.6224C8.97625 21.8057 9.00293 22.0002 9.09577 22.1631C9.1886 22.326 9.33999 22.444 9.51663 22.491C9.69327 22.5381 9.88068 22.5104 10.0376 22.4141C10.1946 22.3177 10.3083 22.1606 10.3536 21.9773C11.3986 17.7634 15.4469 14.427 19.9937 14.427C24.5405 14.427 28.6017 17.7645 29.6464 21.9773C29.6917 22.1606 29.8054 22.3177 29.9624 22.4141C30.1193 22.5104 30.3067 22.5381 30.4834 22.491C30.66 22.444 30.8114 22.326 30.9042 22.1631C30.9971 22.0002 31.0237 21.8057 30.9784 21.6224C29.7654 16.7309 25.1818 13 19.9937 13ZM20.0009 16.8053C17.5626 16.8053 15.5716 18.8716 15.5716 21.4022C15.5716 23.9328 17.5626 26 20.0009 26C22.4392 26 24.4311 23.9328 24.4311 21.4022C24.4311 18.8716 22.4392 16.8053 20.0009 16.8053ZM20.0009 18.2323C21.6961 18.2323 23.0561 19.6428 23.0561 21.4022C23.0561 23.1616 21.6961 24.573 20.0009 24.573C18.3057 24.573 16.9466 23.1616 16.9466 21.4022C16.9466 19.6428 18.3057 18.2323 20.0009 18.2323Z'
                                        fill='white'
                                      />
                                    </svg>
                                  </span>
                                )}
                                <span
                                  className='deleteBlock'
                                  title={lang?.DeleteAction}
                                  onClick={() => deleteMyRequest(uuid)}
                                >
                                  <i
                                    className='fa fa-trash-o'
                                    aria-hidden='true'
                                  ></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )
                  )}
                  {leadListing?.requests?.data?.length == 0 &&
                    isLoader === false && (
                      <tr>
                        <td colSpan='7'>
                          <NoData />
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
              <PaginatedComponentView
                lastPage={leadListing?.requests?.last_page}
                handlePageClick={handleRecentOrderPageClick}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={deleteModal}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "0",
            left: "0%",
            right: "0%",
            bottom: "200px",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            padding: "0",
            border: "0",
            borderRadius: "8px",
            // outline: 'none',
          },
        }}
      >
        <div className='popup_header flex justify-between items-center popupheaderBg'>
          <h4 className='page__title'>Delete Request</h4>
          <button
            className='close_btn--modal'
            onClick={() => setDeleteModal(!deleteModal)}
          >
            Close
          </button>
        </div>
        <div className='card card--block modal_box'>
          <p className='warning'>Are you sure you want to delete it?</p>
          <div className='right__btn text-right mt-4'>
            {deleteLoader ? (
              <FormSpinner />
            ) : (
              <>
                <Link
                  className='btn btn1'
                  to='#'
                  onClick={() => setDeleteModal(!deleteModal)}
                >
                  Cancel
                </Link>
                <Link
                  className='btn btn1 btn2 ml-3'
                  to='#'
                  onClick={() => producerDeleteRequest()}
                >
                  Yes
                </Link>
              </>
            )}
          </div>
        </div>
      </Modal>
      {/*alert popup*/}
      <Modal
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        contentLabel='Upgrade Modal'
        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg text-black text-center'
        overlayClassName='fixed inset-0 bg-black bg-opacity-50'
      >
        <div className='w-16 m-auto mb-2'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            enable-background='new 0 0 47.5 47.5'
            viewBox='0 0 47.5 47.5'
            id='diamond'
          >
            <path
              fill='#bdddf4'
              d='M0 0h-6l-7-9h10l3 9z'
              transform='matrix(1.25 0 0 -1.25 17.5 5)'
            ></path>
            <path
              fill='#5dadec'
              d='m0 0-7 9h-6l3-9H0z'
              transform='matrix(1.25 0 0 -1.25 46.25 16.25)'
            ></path>
            <path
              fill='#4289c1'
              d='M0 0h10L-8-21 0 0Z'
              transform='matrix(1.25 0 0 -1.25 33.75 16.25)'
            ></path>
            <path
              fill='#8ccaf7'
              d='M0 0h-10L8-21 0 0Z'
              transform='matrix(1.25 0 0 -1.25 13.75 16.25)'
            ></path>
            <path
              fill='#8ccaf7'
              d='m0 0-3-9h16l-3 9H0Z'
              transform='matrix(1.25 0 0 -1.25 17.5 5)'
            ></path>
            <path
              fill='#5dadec'
              d='m0 0-8 21H8L0 0Z'
              transform='matrix(1.25 0 0 -1.25 23.75 42.5)'
            ></path>
          </svg>
        </div>
        <h2 className='text-2xl font-bold mb-4  '>Upgrade Your Plan</h2>

        {subscriptionData !== undefined ? (
          <>
          <p>
            <span className=' text-black'>
               {/* As per 00your current Plan you can view{" "}
              {subscriptionData?.buyer_sourcing_engine == -1
                ? "Unlimited"
                : subscriptionData?.buyer_sourcing_engine}{" "}
              Buyer requests. */}
              {
                 subscriptionData?.buyer_sourcing_engine==0?(
                  `As per your current Plan, you cannot view Buyer requests.
                  To view them, please upgrade your Membership Plan.`
                  ):(
                    ` As per your current Plan you can view
                    ${subscriptionData?.buyer_sourcing_engine == -1
                      ? "Unlimited"
                      : subscriptionData?.buyer_sourcing_engine}  
                    Buyer requests.`
                  )
              }
              
            </span>
          </p>
          {
            subscriptionData?.name=="Free"?(
              <p className='text-red mt-2'>
             The Buyer request you received will expire in {daysRemaining} {daysRemaining == 1 ? "day" : "days"}. To view them, please upgrade your membership Plan.    
           </p>
            ):<p className='text-red mt-2'>
            The Buyer request you received, exceeds your current Plan and will expire in {daysRemaining} {daysRemaining == 1 ? "day" : "days"}.    
            </p>
          }
           
           </>  
          
        ) : (
          <p>
            <span className=' text-black'>
              Sorry, You're unable to see any lead at the moment as you don't
              have an active subscription.
            </span>
          </p>
        )}

       
       
        <Link to='/subscription'>
          <button className='btn btn-blue stripe text-white font-bold mt-6'>
            Upgrade{" "}
          </button>
        </Link>
      </Modal>
    </>
  );
}
