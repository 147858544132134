export default {
  ENV: {
    REACT_APP: 'https://devapi.alysei.com',
    //REACT_APP_API: 'http://127.0.0.1:8000/api',
    // REACT_APP_API: 'https://stagingapi.alysei.com/api',
    REACT_APP_API:"https://devapi.alysei.com/api",
    REACT_APP_IMAGE_URL: 'https://stagingapi.alysei.com',
    REACT_APP_STRIPE_KEY: 'pk_test_51Nd6mSHuYOUUdkFcY6JJGv9dLrSqXzedF6JYk6V6qLtFOR7KqObURrDfwyQpmH3JSEHK7pFVzkhdE0jjx7FI1Xnt00isPdNWWD',
    REACT_APP_API_GOOGLE_API_KEY :"AIzaSyBVNQEQqCG-NFtXnwT7g4BAwT6yWN67J68",
    CLIENT_ID :"ca_PNVRkVyzCg3GszHADNBzeXj5VYUch5n6"
  },
};